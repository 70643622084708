.home-view {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: "Futura_XB_Italic";

    .intro{
        width: 100%;
        height: 100%;

        .intro-content{
            position: absolute;
            font-size: 6em;
            @mixin centerXY;
            //color: $color-mobile;

            @media (--desktop) {
                font-size: 10em;
                //color: $color;
            }
        }
    }
}

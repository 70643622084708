@keyframes LOADING-ANIMATION {
  0%   { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}

.views{
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;

    .loading{
        position: absolute;
        @mixin centerXY;
        text-align: center;
        &:before{
            animation:         LOADING-ANIMATION 1s infinite; /* IE 10+, Fx 29+ */
        }
    }
}

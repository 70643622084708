/* variable declarations */
*{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html{
    font-size: 10px;
}

body {
    font-family: $default-font;
    font-size: 12px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    line-height: 1.42857143;

    @media (--desktop){
        font-weight: 300;
    }
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{font-family:inherit;font-weight:500;line-height:1.1;color:inherit}h1 small,h2 small,h3 small,h4 small,h5 small,h6 small,.h1 small,.h2 small,.h3 small,.h4 small,.h5 small,.h6 small,h1 .small,h2 .small,h3 .small,h4 .small,h5 .small,h6 .small,.h1 .small,.h2 .small,.h3 .small,.h4 .small,.h5 .small,.h6 .small{font-weight:normal;line-height:1;color:#777}h1,.h1,h2,.h2,h3,.h3{margin-top:20px;margin-bottom:10px}h1 small,.h1 small,h2 small,.h2 small,h3 small,.h3 small,h1 .small,.h1 .small,h2 .small,.h2 .small,h3 .small,.h3 .small{font-size:65%}h4,.h4,h5,.h5,h6,.h6{margin-top:10px;margin-bottom:10px}h4 small,.h4 small,h5 small,.h5 small,h6 small,.h6 small,h4 .small,.h4 .small,h5 .small,.h5 .small,h6 .small,.h6 .small{font-size:75%}h1,.h1{font-size:36px}h2,.h2{font-size:30px}h3,.h3{font-size:24px}h4,.h4{font-size:18px}h5,.h5{font-size:14px}h6,.h6{font-size:12px}p{margin:0 0 10px}

ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

.section{
    max-width: 1024px;
    width: 80%;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 100px;

    @media (--desktop){
        margin-top: 110px;
    }
}

h1,h2,h3,h4,h5,h6{
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 0.16em;
}

h1{
    letter-spacing: 0.2em;
    font-size: 3.4em;

    &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 0.5px;
        bottom: -4px;
        left: -2px;
        background-color: black;

        @media (--desktop){
            height: 1px;
        }
    }
}

h2{
    position: relative;
    float: left;
    letter-spacing: 0.16em;
    margin-bottom: 20px;
    font-size: 2.4em;

    &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 0.5px;
        bottom: -4px;
        left: -2px;
        background-color: black;

        @media (--desktop){
            height: 1px;
        }
    }
}

h3{
    letter-spacing: 0.16em;
    margin-bottom: 20px;
    font-size: 2em;
}

h6{
    font-size: 1em;
    font-weight: 400;
}

a {
    color: $color-link;
    text-decoration: none;
}
a {
    background-color: transparent;
}

/* FLICKITY */
.flickity-prev-next-button {
  display: none;
}
.flickity-viewport{
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
}

.flickity-page-dots .dot {
    width: 5px;
    height: 5px;
    background: white;
    @mixin transform translateZ(0);
}
.flickity-page-dots {
    bottom: 10px;
    @mixin transform translateZ(0);
}

html.desktop{
    .views, .menu{
        min-width: 480px;
    }
}

.title{
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 40px;

    h1{
        position: relative;
        float: left;
    }
}

.m404{
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;

    .copy{
        position: absolute;
        @mixin centerXY;
        text-align: center;

        .title{
            letter-spacing: 0.2em;
            font-size: 6.4em;
            font-weight: 100;
            text-align: center;
        }
        h5{
            position: relative;
            float: left;
            width: 100%;
            text-decoration: none;
            text-transform: none;
            width: 100%;
            font-weight: 300;
        }
    }
}

.hidden{
    display: none;
}

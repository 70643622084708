/* Generated by Font Squirrel (https://www.fontsquirrel.com) on July 7, 2016 */
@font-face {
    font-family: 'Futura_XB_Italic';
    src: url('../fonts/ufonts.com_futura-condensed-extra-bold-italic-webfont.woff') format('woff'),
         url('../fonts/ufonts.com_futura-condensed-extra-bold-italic-webfont.ttf') format('truetype'),
         url('../fonts/ufonts.com_futura-condensed-extra-bold-italic-webfont.svg#futura_condensed_extra_boldIt') format('svg');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'Futura_XB';
    src: url('../fonts/ufonts.com_futura-condensed-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/ufonts.com_futura-condensed-extrabold-webfont.woff') format('woff'),
         url('../fonts/ufonts.com_futura-condensed-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/ufonts.com_futura-condensed-extrabold-webfont.svg#futuracondensed_extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* mixin declarations */
@define-mixin setBg $color {
  background: $color;
}

@define-mixin transform $transforms {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@define-mixin translateY $percentage {
    @mixin transform translateY( $percentage );
}

@define-mixin translateX $percentage {
    @mixin transform translateX( $percentage );
}

@define-mixin centerX {
    left: 50%;
    @mixin transform translateX(-50%);
}

@define-mixin centerY {
    top: 50%;
    @mixin transform translateY(-50%);
}

@define-mixin centerXY {
    left: 50%;
    top: 50%;
    @mixin transform translate3d(-50%,-50%,0);
}

@define-mixin rotate $deg {
    @mixin transform rotate( $(deg)deg );
}

@define-mixin scale $scale {
    @mixin transform scale( $scale );
}

@define-mixin translate $x, $y {
    @mixin transform translate( $x, $y );
}

@define-mixin gradientBackground $colorStep1: rgba(127,255,219,1), $colorStep2: rgba(178, 255, 158, 1) {
    background-color: $(colorStep1); /* Old browsers */
    background-image: -moz-linear-gradient(-45deg, $(colorStep1) 0%, $(colorStep2) 100%); /* FF3.6-15 */
    background-image: -webkit-linear-gradient(-45deg, $(colorStep1) 0%, $(colorStep2) 100%); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(135deg, $(colorStep1) 0%, $(colorStep2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$(colorStep1)', endColorstr='$(colorStep2)',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.work-view{

    .work-wrapper{
        position: relative;
        float: left;
        width: 100%;

        .work{
            position: relative;
            float: left;
            width: 100%;
            margin: 20px 0 0 0;
            padding-bottom: 60px;
            @mixin transform translateZ(0);
            z-index: 0;

            .img-container{
                position: relative;
                //background-color: $color3-faded;
                overflow: hidden;
                width: 100%;
                padding-top: 100%;

                @mixin gradientBackground;

                @media (--desktop){
                    width: 100%;
                    min-height: 480px;
                    padding: 0;
                }

                &.mobile{
                    //height: 200px;
                    //padding: 0;
                    background-color: $color-white;
                    background-image: none;

                }

                .img-cell{
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    margin-right: 10px;
                    background-color: $color3-faded;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    @mixin transform translateZ(0);

                    img{
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: auto;
                        @mixin centerX;
                    }
                }

                .project-image{
                    position: absolute;
                    height: 100%;
                    width: auto;
                    opacity: 0;
                    @mixin centerXY;
                    transition: opacity 0.8s $bezierOutExpo;
                    will-change: opacity;

                    @media (--desktop){
                        width: 100%;
                        height: auto;
                    }

                }

                .project-video{
                    & canvas{
                        transform: translateX(-50%) translateY(-50%);
                        left: 50% !important;
                        top: 50% !important;
                        width: auto !important;
                        height: 100% !important;

                        @media (--desktop){
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
            }

            $thumbnails-size: 120px;
            .thumbnails-container{
                position: relative;
                overflow-x: scroll;
                overflow-y: hidden;
                width: 100%;
                height: calc( $thumbnails-size + 16px);
                transition: height 0.6s $bezierOutExpo;
                transition-delay: 0.2s;

                ul{
                    position: relative;
                    display: inline-block;
                    white-space: nowrap;

                    .thumbnail-item{
                        //background-image: url('../assets/shared/works/e3_booth.jpg');
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;

                        position: relative;
                        margin: 5px 2px 0 2px;
                        background-color: $color3-faded;
                        display: inline-block;
                        width: $thumbnails-size;
                        height: $thumbnails-size;
                        cursor: pointer;

                        @mixin gradientBackground;

                        &:first-child{
                            margin-left: 0;
                        }

                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                &.collapsed{
                    height: 0;
                }
            }
            .text-container{
                z-index: 0;
                @mixin transform translateZ(0);

                .description{
                    a {
                        cursor: pointer;
                        font-weight: bold;
                        text-decoration: none;
                        background-color: transparent;
                    }
                }
                h3{
                    width: 86%;
                }
                h6{
                    color: #aaa;
                }
            }
        }
    }
}

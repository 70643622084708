.about-view {
    .img-container{
        position: relative;
        background-color: $color-white;
        overflow: hidden;
        width: 100%;
        padding-top: 100%;

        @mixin gradientBackground;

        @media (--tablet-portrait){
            width: 100%;
            min-height: 480px;
            padding: 0;
        }

        /*&.mobile{
            //height: 200px;
            //padding: 0;
            background-color: $color-white;

        }*/

        img{
            position: absolute;
            height: 100%;
            width: auto;
            opacity: 0;
            @mixin centerY;
            right: 0;
            transition: opacity 0.8s $bezierOutExpo;
            will-change: opacity;

            /*@media (--desktop){
                width: 100%;
                height: auto;
            }*/

        }
    }

    .description{
        margin-top: 40px;
        padding-bottom: 40px;
    }
}

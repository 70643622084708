.activity-view {

    a {
        text-decoration: none;
        background-color: transparent;
    }

    a:hover {
        color:inherit;
        text-decoration: none;
    }
    a:visited {
        color:inherit;
        text-decoration: none;
    }
    a:active {
        color:inherit;
        text-decoration: none;
    }
    a:link {
        color:inherit;
        text-decoration: none;
    }

    .filters{
        display: inline-block;
        width: 100%;
        font-size: 11px;
        margin: 10px 0;
        text-transform: uppercase;

        ul li{
            display: inline-block;
        }

        .filter-type{
            display: inline-block;
            padding-left: 18px;
            position: relative;
            float: left;
            cursor: pointer;
            margin: 2px 4px 2px 2px;
            min-width: 100px;

            &:first-child{
                margin-left: 0;
            }

            &:before{
                content: "";
                position: absolute;
                width: 14px;
                height: 14px;
                border: 1px solid #ccc;
                border-radius: 7px;
                left: 0px;
                @mixin centerY;
                margin-top: -1px;

            }

            &:hover{
                &:before{
                    background-color: #ccc;
                }
            }

            &.active{
                &:before{
                    background-color: $color3-faded;
                }
            }
        }
    }

    .colors{
        display: inline-block;
        width: 100%;
        font-size: 11px;
        margin: 10px 0;
        text-transform: uppercase;

        ul li{
            display: inline-block;
        }

        .color-type{
            padding-left: 18px;
            position: relative;
            float: left;
            cursor: pointer;
            min-width: 100px;
            margin: 2px 4px 2px 2px;

            &:first-child{
                margin-left: 0;
            }

            &:before{
                content: "";
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 7px;
                left: 0px;
                @mixin centerY;
                margin-top: -1px;
                border: 1px solid #777;
            }
            &:hover{
                &:before{
                    background-color: #777;
                }
            }

            &.active{
                &:before{
                    background-color: #777;
                }
            }
        }

        @each $val, $i in job, installation, talk, interview, b-reel, press, workshop, award, teaching, exhibition, jury {
            .color-type[data-category='$(val)']{
                &:before{
                    border: 1px solid $color$(i);
                }

                &:hover{
                    &:before{
                        background-color: $color$(i);
                    }
                }

                &.active{
                    &:before{
                        background-color: $color$(i);
                    }
                }
            }
        }
    }

    .activity-container{
        display: inline-block;
        width: 100%;
        font-size: 1em;
        margin-bottom: 40px;

        /*@media (--desktop) {
            width: 100%;
            position: relative;
            float: left;
        }*/

        .year-view{
            position: relative;
            float: left;
            width: 100%;

            @media (--desktop) {
                width: 50%;
            }

            .activity-list{
                position: relative;
                float: left;
                margin-bottom: 10px;
                width: 100%;

                .activity-item{
                    font-size: 12px;
                    width: 100%;
                    position: relative;
                    float: left;

                    .item-wrapper{
                        position: relative;
                        float: left;
                        display: inline-block;
                        min-width: 300px;

                        .link-wrapper{
                            position: relative;
                            float: left;

                            &:before, &:after{
                                font-weight: 800;
                            }

                            &:hover{
                                &:before{
                                    @mixin transform translateX(-100%);
                                    opacity: 1;
                                }

                                &[data-category='installation']:after{
                                    @mixin transform translateX(100%);
                                    opacity: 1;
                                }

                                .link:before{
                                    width: 100%;
                                }
                            }

                            &[data-category='installation']:after, &:before{
                                text-transform: uppercase;
                                font-size: 10px;
                                line-height: 20px;
                                position: absolute;
                                transition: transform 0.3s $bezierOutExpo, opacity 0.3s $bezierOutExpo;
                                transition-delay: 0.3s;
                                opacity: 0;
                                color: $color1;
                                z-index: -1;
                                will-change: transform, opacity;
                            }

                            &[data-category='installation']:after{
                                right: -10px;
                                @mixin transform translateX(-10px);

                                @media (--desktop){
                                    content: attr(data-installation);
                                }
                            }

                            &:before{
                                left: -10px;
                                @mixin transform translateX(10px);

                                @media (--desktop){
                                    content: attr(data-category);
                                }
                            }

                            .link{
                                position: relative;
                                float: left;
                                width: 100%;
                                padding: 2px 4px 2px 2px;

                                &:before{
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 0;
                                    height: 100%;
                                    transition: width 0.3s $bezierOutExpo;
                                    transform-origin: left;
                                    transition-delay: 0.3s;
                                    background-color: $color1;
                                    will-change: width;
                                }

                                a{
                                    position: relative;
                                    float: left;
                                    cursor: pointer;
                                }
                            }
                        }

                        /* CREATE COLORS FOR CATEGORIES */
                        @each $val, $i in job, installation, talk, interview, b-reel, press, workshop, award, teaching, exhibition, jury {
                            .link-wrapper[data-category='$(val)']{
                                &:before{
                                    color: $color$(i);
                                }


                                .link:before{
                                    background-color: $color$(i);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.debug{
            .link:before{
                width: 100% !important;
            }
        }
    }
}

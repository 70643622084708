.menu{
    position: fixed;
    width: 100%;
    z-index: --menu;

    .menu-view{
        position: relative;
        float: left;
        width: 100%;
        height: 60px;
        background-color: $color-white;
        box-shadow: 0 0 0 -2px #bbb;
        transition: transform 0.6s $bezierOutExpo, box-shadow 0.6s $bezierOutExpo;
        transform: translate3d(0,-100%,0);

        &.visible{
            transform: translate3d(0,0,0);
            box-shadow: 0 0 12px -2px #bbb;
        }

        .menu-content{
            display: inline-block;
            background-color: $color-white;
            position: relative;
            @mixin centerX;
            margin-top: 22px;

            ul{
                li{
                    position: relative;;
                    display: inline-block;
                    text-align: center;
                    cursor: pointer;
                    text-transform: uppercase;
                    margin: 0 8px;
                    letter-spacing: 0.16em;
                    font-weight: 300;
                    transform: scaleZ(1);

                    a {
                        text-decoration: none;
                        background-color: transparent;
                    }

                    a:hover {
                        color:inherit;
                        text-decoration: none;
                    }
                    a:visited {
                        color:inherit;
                        text-decoration: none;
                    }
                    a:active {
                        color:inherit;
                        text-decoration: none;
                    }
                    a:link {
                        color:inherit;
                        text-decoration: none;
                    }

                    &:before{
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 100%;
                        height: 1px;
                        background-color: #000;

                        transform: scaleX(0);
                        transition: transform 0.4s $bezierOutExpo;
                        will-change: transform;
                    }

                    &.selected{
                        &:before{
                            //width: 100%;
                            transform: scaleX(1);
                        }
                    }

                    &:first-child{
                        margin-left: 0;
                    }

                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
